import Chart from 'chart.js/auto';

export function co2LineChart(canvas) {
  let datasets = JSON.parse(canvas.dataset.datasets)
  let showLegend = datasets.length > 1
  let ctx = canvas
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: datasets
    },
    options: {
      aspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        }
      },elements: {
        point:{
            radius: 0
        }
      },
      plugins: {
        legend: {
          display: showLegend,
        }
      }
    }
  })
}

export function eLineChart(canvas) {
  let datasets = JSON.parse(canvas.dataset.datasets)
  let ctx = canvas
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: datasets
    },
    options: {
      aspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        }
      },elements: {
        point:{
            radius: 0
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      }
    }
  })
}

export function h2oLineChart(canvas) {
  let datasets = JSON.parse(canvas.dataset.datasets)
  let ctx = canvas
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: datasets
    },
    options: {
      aspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        }
      },elements: {
        point:{
            radius: 0
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      }
    }
  })
}